<template>
  <div class="newsInfo">
    <div class="title">{{info.title}}</div>
    <div class="date">日期：{{info.pubDate | dateFilter}}</div>
    <div class="content" v-html="info.html"></div>
    <div class="source">来源：{{info.source}}</div>
  </div>
</template>

<script>
import moment from 'moment'
import { getNewsInfo } from "@/utils/api";
import {uwStatisticAction} from "../../utils/util";
export default {
  data() {
    return{
      info:{}
    }
  },
  filters: {
    dateFilter (value){
      return moment(value).format('YYYY-MM-DD HH:MM:SS')
    }
  },
  mounted(){
    uwStatisticAction('/NewsInfoDetail','newsInfo乡村新闻详情')
    this.togetNewsInfo()
  },
  methods:{
    togetNewsInfo(){
      getNewsInfo({id: this.$route.query.id}).then(res=>{
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.info = res.data.data
          }
        }
      })
    }
  }
}
</script>

<style lang="less">
.newsInfo{
  padding: 0.48rem;
  img{
    width: 100%;
  }
  .title{
    color: #333333;
    font-size: 0.48rem;
    // margin: 0.16rem 0 0.4rem 0;
  }
  .date{
    font-size: 0.22rem;
    color: #999999;
    margin: 0.15rem 0;
  }
  .content{
    padding: 0;
    h1,h2,h3,h4,h5{
      font-size: 18px;
      font-weight: bold;
    }
  }
  .source{
    color: #999999;
    font-size: 15px;
  }
}
</style>